import React, { useState, useMemo } from 'react';
import allergens from './allergens'; // Importiere die Allergene

const Cart = ({ cart, removeFromCart }) => {
  const [showAllergens, setShowAllergens] = useState(false); // Zustand zum Umschalten der Allergenanzeige
  const [isExpanded, setIsExpanded] = useState(false); // Zustand für die erweiterte Ansicht

  // Funktion zum Abrufen der Allergenbeschreibung
  const getAllergenDescription = (code) => {
    const allergen = allergens.find(a => a.code === code);
    return allergen ? allergen.description : '';
  };

  // Berechne die Gesamtsumme
  const total = useMemo(() => {
    return cart.reduce((sum, item) => sum + (item.selectedPrice !== '-' ? parseFloat(item.selectedPrice) * item.quantity : 0), 0).toFixed(2);
  }, [cart]);

  // Berechne die Gesamtanzahl der Produkte im Warenkorb
  const totalItems = useMemo(() => {
    return cart.reduce((sum, item) => sum + item.quantity, 0);
  }, [cart]);

  return (
    <div className={`fixed bottom-0 left-1/2 transform -translate-x-1/2 ${isExpanded ? 'h-96' : 'h-16'} w-full max-w-md bg-primary dark:bg-gray-700 p-4 rounded-t-3xl shadow-lg transition-all duration-300 neon-effect`}>
      <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsExpanded(!isExpanded)}>
        <div className="flex items-center">
          <span className="mr-2 text-white">{isExpanded ? '▼' : '▲'}</span>
          <h2 className="text-xl font-bold text-white dark:text-white">Warenkorb</h2>
        </div>
        <div className="flex items-center">
          <span className="bg-red-500 text-white px-2 py-1 rounded mr-2">{totalItems}</span>
          <span className="text-lg font-bold text-white dark:text-white">{total} €</span>
        </div>
      </div>
      {isExpanded && (
        <div className="overflow-auto max-h-80 mt-4">
          <ul>
            {cart.map((item, index) => (
              <React.Fragment key={index}>
                <li className="text-gray-700 dark:text-gray-300 mb-2">
                  <div className="flex justify-between items-center">
                    <span>{item.quantity}x {item.name} {item.variantLabel && `(${item.variantLabel})`}</span>
                    <span className="ml-auto text-right">{item.selectedPrice} €</span>
                    <button
                      onClick={() => removeFromCart(index)}
                      className={`bg-red-500 text-white ml-2 mr-2 px-2 py-1 rounded ${isNaN(item.selectedPrice) ? 'cursor-not-allowed opacity-50' : ''}`}
                      disabled={isNaN(item.selectedPrice)}
                    >
                      x
                    </button>
                  </div>
                  {item.dressing && (
                    <div className="text-sm text-gray-500 dark:text-gray-400 ml-2">
                      Dressing: {item.dressing}
                    </div>
                  )}
                  {showAllergens && item.allergens && (
                    <ul className="text-xs text-red-500 dark:text-red-300 ml-2">
                      {item.allergens.split(',').map((code) => (
                        <li key={code}>{getAllergenDescription(code)}</li>
                      ))}
                    </ul>
                  )}
                </li>
                {index < cart.length - 1 && <hr className="my-2 border-dashed border-gray-400" />}
              </React.Fragment>
            ))}
          </ul>
          <button
            onClick={() => setShowAllergens(!showAllergens)}
            className="mt-4 bg-white text-primary px-4 py-2 rounded"
          >
            {showAllergens ? 'Allergene ausblenden' : 'Allergene anzeigen'}
          </button>
        </div>
      )}
    </div>
  );
};

export default Cart;