import React, { useState } from 'react';
import { FaMapMarkerAlt, FaPhoneAlt, FaUtensils, FaClock, FaInfoCircle } from 'react-icons/fa';
import logo from './logo-b.png'; // Importiere das Logo

const LandingPage = ({ onEnter }) => {
  const [isOpenHoursOpen, setIsOpenHoursOpen] = useState(false);
  const [isImpressumOpen, setIsImpressumOpen] = useState(false);

  const toggleOpenHours = () => setIsOpenHoursOpen(!isOpenHoursOpen);
  const toggleImpressum = () => setIsImpressumOpen(!isImpressumOpen);

  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-cover bg-center bg-fixed" style={{ backgroundImage: "url('./stern-hintergrund.jpg')" }}>
      <div className="max-w-2xl w-full bg-white dark:bg-gray-700 p-6 rounded-lg shadow-lg text-center">
        <div className="flex items-center justify-center mb-4">
          <img src={logo} alt="Logo" className="h-12 w-12 mr-2" />
          <h1 className="text-4xl font-bold text-primary dark:text-secondary">Stern Burger</h1>
        </div>
        
        <section className="mb-8 border-b border-gray-300 pb-4">
          <p className="mb-4">Hauptstraße 218, 67067 Ludwigshafen am Rhein</p>
          <p className="mb-4">
            <a href="https://www.google.com/maps/search/?api=1&query=Hauptstraße+218,+67067+Ludwigshafen+am+Rhein" target="_blank" rel="noopener noreferrer" className="button-gagalin inline-block mt-4 px-6 py-3 bg-primary text-white rounded-lg hover:bg-primary-dark text-lg font-semibold flex items-center justify-center">
              <FaMapMarkerAlt className="mr-2" /> Route starten
            </a>
          </p>
        </section>
        
        <section className="mb-8 border-b border-gray-300 pb-4">
          <p className="mb-4 text-2xl font-bold text-secondary animate-pulse button-gagalin">Anrufen, bestellen, abholen oder liefern lassen</p>
          <p className="mb-4">
            <a href="tel:062154965413" className="button-gagalin inline-block mt-4 px-6 py-3 bg-primary text-white rounded-lg hover:bg-primary-dark text-lg font-semibold flex items-center justify-center">
              <FaPhoneAlt className="mr-2" /> 0621 54965413
            </a>
          </p>
        </section>
        
        <section className="mb-8 border-b border-gray-300 pb-4">
          <button
            onClick={onEnter}
            className="button-gagalin inline-block mt-4 px-6 py-3 text-white rounded-lg hover:bg-primary-dark text-lg font-semibold flex items-center justify-center w-full"
            style={{ backgroundColor: 'rgb(194, 48, 49)' }}
          >
            <FaUtensils className="mr-2" /> Zum Menü
          </button>
        </section>
        
        <section className="mb-8 border-b border-gray-300 pb-4">
          <h2 className="text-2xl font-semibold mb-2 cursor-pointer flex items-center justify-center" onClick={toggleOpenHours}>
            <FaClock className="mr-2" /> Öffnungszeiten
          </h2>
          {isOpenHoursOpen && (
            <table className="table-auto mx-auto text-left">
              <tbody>
                <tr>
                  <td className="px-4 py-2 font-semibold">Montag</td>
                  <td className="px-4 py-2">11:00–22:00</td>
                </tr>
                <tr>
                  <td className="px-4 py-2 font-semibold">Dienstag</td>
                  <td className="px-4 py-2">11:00–22:00</td>
                </tr>
                <tr>
                  <td className="px-4 py-2 font-semibold">Mittwoch</td>
                  <td className="px-4 py-2">11:00–22:00</td>
                </tr>
                <tr>
                  <td className="px-4 py-2 font-semibold">Donnerstag</td>
                  <td className="px-4 py-2">11:00–22:00</td>
                </tr>
                <tr>
                  <td className="px-4 py-2 font-semibold">Freitag</td>
                  <td className="px-4 py-2">15:00–22:00</td>
                </tr>
                <tr>
                  <td className="px-4 py-2 font-semibold">Samstag</td>
                  <td className="px-4 py-2">11:00–22:00</td>
                </tr>
                <tr>
                  <td className="px-4 py-2 font-semibold">Sonntag</td>
                  <td className="px-4 py-2">11:00–22:00</td>
                </tr>
              </tbody>
            </table>
          )}
        </section>
        
        <section className="mb-8 border-b border-gray-300 pb-4">
          <h2 className="text-2xl font-semibold mb-2 cursor-pointer flex items-center justify-center" onClick={toggleImpressum}>
            <FaInfoCircle className="mr-2" /> Impressum
          </h2>
          {isImpressumOpen && (
            <div>
              <p>Stern Burger</p>
              <p>Hauptstraße 218</p>
              <p>67067 Ludwigshafen am Rhein</p>
              <p>Telefon: 0621 54965413</p>
              <p>E-Mail: info@stern-burger.de</p>
              <p>Vertreten durch: Yakup Yildiz</p>
              <p>Handelsregister: Amtsgericht Ludwigshafen, HRB</p>
            </div>
          )}
        </section>
      </div>
    </div>
  );
};

export default LandingPage;