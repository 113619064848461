const allergens = [
  { code: 'A', description: 'Getreide / Gluten' },
  { code: 'B', description: 'Krebstiere/Krustentiere' },
  { code: 'C', description: 'Eier' },
  { code: 'D', description: 'Fische' },
  { code: 'E', description: 'Erdnüsse' },
  { code: 'F', description: 'Milch' },
  { code: 'G', description: 'Nüsse' },
  { code: 'H', description: 'Senf' },
  { code: 'I', description: 'Sesam' },
  { code: 'J', description: 'Nüsse' },
  { code: 'K', description: 'Senf' },
  { code: 'L', description: 'Soja' },
  { code: '1', description: 'mit Farbstoff' },
  { code: '2', description: 'mit Konservierungsstoff' },
  { code: '3', description: 'mit Antioxidationsmittel' },
  { code: '4', description: 'geschwefelt' },
  { code: '5', description: 'mit Geschmacksverstärker' },
  { code: '6', description: 'geschwärzt' },
  { code: '7', description: 'mit Phosphat' },
  { code: '8', description: 'mit Süßungsmittel' },
  { code: '9', description: 'mit Nitritpöckelsalz' },
];

export default allergens;