import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import LandingPage from './LandingPage';
import reportWebVitals from './reportWebVitals';

const RootComponent = () => {
  const [showLandingPage, setShowLandingPage] = useState(true);

  const handleEnter = () => {
    setShowLandingPage(false);
  };

  return (
    <React.StrictMode>
      {showLandingPage ? <LandingPage onEnter={handleEnter} /> : <App />}
    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<RootComponent />);

reportWebVitals();