const data = {
    categories: [
      'Alle',
      'Burger',
      'HotDogs',
      'Wraps',
      'Salat',
      'Fries',
      'Fingerfood',
      'Softdrinks',
      'Dessert',
      'Dips'
    ],
    items: {
      Burger: [
        { name: 'Monster', description: '100% Rindfleisch, Salatmix, gebratene Zwiebeln, frische Zwiebeln, 3x Cheddar, eingelegte Gurken, Tomaten, House-Soße', price: '-/- /15.00', allergens: 'A,C,F,H,I,K,L,1,7,8' },
        { name: 'Triple Cheese', description: '100% Rindfleisch, Rucola, frische Zwiebeln, Cheddar, Mozzarella, Gorgonzola, Special-Soße', price: ' -/13.00/- ', allergens: 'A,F,H,I,1,7' },
        { name: 'Hamburger', description: '100% Rindfleisch, Salatmix, frische Zwiebeln, eingelegte Gurken, Tomaten, Senf, Mayo, Ketchup', price: '8.90/10.90/12.90', allergens: 'A,C,H,I,1,8' },
        { name: 'Cheeseburger', description: '100% Rindfleisch, Salatmix, Cheddar, frische Zwiebeln, eingelegte Gurken, Tomaten, Senf, Mayo, Ketchup', price: '9.40/11.40/13.40', allergens: 'A,C,F,H,I,1,7,8' },
        { name: 'House', description: '100% Rindfleisch, Salatmix, frische Zwiebeln, Cheddar, House-Soße', price: '9.40/11.40/13.40', allergens: 'A,F,H,I,1,7,8' },
        { name: 'BBQ', description: '100% Rindfleisch, Salatmix, Cheddar, gebratene Zwiebeln, frische Gurken, Tomaten, Barbecue-Soße', price: '9.90/11.90/13.90', allergens: 'A,C,F,H,I,K,L,1,8' },
        { name: 'Gorgonzola', description: '100% Rindfleisch, Rucola, gebratene Zwiebeln, frische Gurken, Gorgonzola, House-Soße', price: '9.90/11.90/13.90', allergens: 'A,F,H,I,8' },
        { name: 'American', description: '100% Rindfleisch, Salatmix, Cheddar, Röstzwiebeln, Mayo, Special-Soße', price: '9.90/11.90/13.90', allergens: 'A,F,H,I,1,7' },
        { name: 'Mexican', description: '100% Rindfleisch, Salatmix, Cheddar, frische Zwiebeln, Jalapenos, Chili-Soße', price: '9.50/11.50/13.50', allergens: 'A,F,H,I,K,1,7' },
        { name: 'Stern Burger', description: 'Salatmix, Ceddar, Mozzarella, frische Zwiebeln und scharfe Soße', price: '9.50/11.50/13.50', allergens: 'A,F,H,I,K,1,7' },
        { name: 'Italian', description: '100% Rindfleisch, Salatmix, Rucola, Tomaten, Basilikum, Mozzarella, Balsamico', price: '9.80/11.80/13.80', allergens: 'A,F,H,I,1,3,4' },
        { name: 'Fire Cracker', description: '100% Rindfleisch, Salatmix, Cheddar, Onion Rings, Jalapenos, Tomaten, Fire Cracker-Soße, House-Soße', price: '10.40/12.40/14.40', allergens: 'A,C,F,H,I,K,L,1,7,8' },
        { name: '1001', description: '100% Rindfleisch, Salatmix, Cheddar, frische Zwiebeln, gebratene Paprika, Rinderschinken, Tomaten, Oliven, Gewürzpaste, Garlic-Soße', price: '10.40/12.40/14.40', allergens: 'A,C,F,H,I,1,7,8' },
        { name: 'German', description: '100% Rindfleisch, Salatmix, Cheddar, Pommes, gebratene Zwiebeln, Ketchup, Mayo', price: '9.70/11.70/13.70', allergens: 'A,C,H,I,K,L,1,7,8' },
        { name: 'Cheezy', description: '100% Rindfleisch, Cheddar, Nachos, Jalapenos, Chili Cheese-Soße', price: '10.40/12.40/14.40', allergens: 'A,C,F,H,I,K,L,1,2,7,8' },
        { name: 'Hot Chicken', description: 'Salatmix, Cheddar, Jalapenos, Chili-Soße', price: '8.90', allergens: 'A,F,H,I,K,1,7' },
        { name: 'Chicken', description: 'Crispy Chicken Patties, Salatmix, Mayo', price: '8.90', allergens: 'A,C,H,I,1,7' },
        { name: 'Crunchy Chicken', description: 'Crispy Chicken Patties, Salatmix, Cheddar, Röstzwiebeln, Special-Soße', price: '9.20', allergens: 'A,C,F,H,I' },
        { name: 'Sweet Chicken', description: 'Crispy Chicken Patties, Salatmix, Nachos, Cheddar, Mayo, Senf-Soße', price: '9.20', allergens: 'A,C,F,H,I,K,L,1,7,8' },
        { name: 'Veggie', description: 'Vegan-Patties, Salatmix, Käse, Röstzwiebeln, Ketchup, Mayo oder', price: '9.20', allergens: 'A,C,H,I,K,1,7,8' },
        { name: 'Vegan', description: 'Vegan-Patties, Salatmix, frische Zwiebeln, gebratene Paprika, Ketchup', price: '9.20', allergens: 'A,H,I,K,1,7' },
      ],
      HotDogs: [
        { name: 'HotDog', description: 'Wurst, Senf, eingelegte Gurken, Ketchup, Röstzwiebeln, Remoulade', price: '6.50', allergens: 'A,C,F,H,I,K,L,1,7,8' },
        { name: 'AngryDog', description: 'Wurst, Hot-Chili Soße. Jalapeños, Oliven, frische Zwiebeln', price: '6.50', allergens: 'A,I,K,1,2,3,5,7,8' },
        { name: 'SweetDog', description: 'Wurst, Mozzarella, Mais, Mayo', price: '6.50', allergens: 'A,C,F,I,K,1,2,3,5,7,8' },
        { name: 'Currywurst', description: 'Rindswurst, hausgemachte Curry-Soße, Curry-Pulver', price: '6.70', allergens: 'F,H,K,1,2,3,5,7,9' },
        { name: 'Currywurst Special', description: 'Rindswurst, hausgemachte Curry-Soße, Curry-Pulver, frische Zwiebeln, Jalapeños', price: '6.70', allergens: 'F,H,K,1,2,3,5,7,9' },
      ],
      Wraps: [
        { name: 'Chicken', description: 'Knusprige Hähnchenbrustfilets, Salat, Mozzarella, Tomaten, Mayo', price: '8.90', allergens: 'A,F,K,L,1,2,7' },
        { name: 'Sweet Chicken', description: 'Knusprige Hähnchenbrustfilets, Salat, Mozzarella, Mais, Mayo', price: '8.90', allergens: 'A,C,F,K,L,1,2,7,8' },
        { name: 'Hot Chicken', description: 'Salatmix, frische Zwiebeln, Jalapeños, Chili-Soße, Mozzarella Käse', price: '8.90', allergens: 'A,F,H,I,K,1,7' },
        { name: 'Vegan', description: 'Geschnittene Tofu-Würstchen, Salat, Tomaten, Salsa-Soße', price: '9.10', allergens: 'A,K,L,1,7,8' },
        { name: 'Homestyle', description: '100% Rindfleisch 130g, Salat, Tomaten, Gurken, frische Zwiebeln, House-Soße', price: '8.90', allergens: 'A,C,F,K,L,1,7' },
      ],
      Salat: [
        { name: 'Grüner Salat', description: 'Salatmix, Cherry, Tomate, Gurken, Oliven, Mais', price: '6.90', allergens: 'A,C,F,K,L,5', dressings: ['Joghurt', 'Balsamiko', 'Kräuter', 'French', 'Essig & Öl'] },
        { name: 'Caesar Salat', description: 'Salatmix, Cherry, Tomate, Gurken, Crouton, Parmesan, Käse', price: '7.90', allergens: 'A,C,F,K,L,1,2,3,5', dressings: ['Joghurt', 'Balsamiko', 'Kräuter', 'French', 'Essig & Öl'] },
        { name: 'Coleslaw', description: 'Weißkraut und Karotten in einem weißen Salatdressing', price: '5.50', allergens: 'A,C,D,F,G,H,K,7' },
        { name: 'Krautsalat', description: 'Weißkraut und Gemüsepaprika in einem klaren Dressing', price: '5.50', allergens: 'J,K,2' },
        { name: 'Farmersalat', description: 'Gemüsesalat mit frischen Karotten, Weißkohl, Ananas und Sellerie', price: '5.50', allergens: 'A,C,D,F,G,H,K,7' },
      ],
      Fries: [
        { name: 'Pommes Frites', description: '', price: '3.50', allergens: '7' },
        { name: 'Crinkle Wedges', description: '', price: '3.90', allergens: '7' },
        { name: 'Twister Fries', description: '', price: '4.00', allergens: 'A,7' },
        { name: 'Süsskartoffel Pommes', description: '', price: '4.30', allergens: '7' },
      ],
      Fingerfood: [
        { name: 'Nuggets', description: '', price: '6.99/8.99/12.99', allergens: 'A,C,G,1,5,8' },
        { name: 'Wings', description: '', price: '6.99/9.49/13.49', allergens: '1,K' },
        { name: 'Mozarella Sticks', description: '', price: '5.99/8.49/12.49', allergens: 'F' },
        { name: 'Oninion Rings', description: '', price: '6.29/8.89/12.89', allergens: 'A' },
      ],
      Dips: [
        { name: 'Sour-Cream', description: '', price: '0.70' },
        { name: 'Sweet-Chili', description: '', price: '0.70' },
        { name: 'Pommes Cream', description: '', price: '0.70' },
        { name: 'Fire Cracker', description: '', price: '0.70' },
        { name: 'Knoblauch', description: '', price: '0.70' },
        { name: 'House', description: '', price: '0.70' },
        { name: 'Chili', description: '', price: '0.70' },
        { name: 'Curry', description: '', price: '0.70' },
        { name: 'Ketchup', description: '', price: '0.70' },
        { name: 'Mayo', description: '', price: '0.70' },
        { name: 'Senf', description: '', price: '0.70' },
        { name: 'BBQ', description: '', price: '0.70' },
        { name: 'Salsa', description: '', price: '0.70' },
        { name: 'Spezial', description: '', price: '0.70' },
      ],
      Softdrinks: [
        { name: 'Fritz-Kola', description: '', price: '2.99', allergens: '' },
        { name: 'Fritz-Kola Zero', description: '', price: '2.99', allergens: '' },
        { name: 'Fritz-Limo Orange', description: '', price: '2.99', allergens: '' },
        { name: 'Fritz-Limo Zitrone', description: '', price: '2.99', allergens: '' },
        { name: 'Fritz-Bio Apfelschorle', description: '', price: '2.99', allergens: '' },
        { name: 'Fritz-Kola Mischmasch', description: '', price: '2.99', allergens: '' },
        { name: 'Uludağ', description: '', price: '2.99', allergens: '' },
        { name: 'Dr. Pepper', description: '', price: '2.80', allergens: '' },
        { name: 'Bionade Zitrone/Orange', description: '', price: '2.80', allergens: '' },
        { name: 'Eistee Pfirsich', description: '', price: '2.80', allergens: '' },
        { name: 'Eistee Zitrone', description: '', price: '2.80', allergens: '' },
        { name: 'Red Bull', description: '', price: '2.80', allergens: '' },
        { name: 'Capri Sonne', description: '', price: '1.00', allergens: '' },
        { name: 'Powerade', description: '', price: '2.80', allergens: '' },
        { name: 'Durstlöscher', description: '', price: '1.50', allergens: '' },
        { name: 'Mineralwasser', description: '', price: '2.20', allergens: '' },
        { name: 'Wasser Still', description: '', price: '2.20', allergens: '' },
        { name: 'Ayran', description: '', price: '1.50', allergens: '' },
      ],
      Dessert: [
        { name: 'Milka Chocolate Donut', description: '', price: '2.49', allergens: 'A,C,F,G,H,I,1,2' },
        { name: 'Oreo Donut', description: '', price: '2.49', allergens: 'A,C,F,G,H,I,1,2' },
      ],
    }
  };
  
  export default data;